<template>
  <el-row class="wrapper">
    <el-col :span="18">
      <div class="legend">
        <!-- <el-row class="lg__row">
          <el-col :span="4">
            {{ $t('value_map.level') }}
          </el-col>
          <el-col :span="5">
            {{ $t('value_map.light') }}
          </el-col>
          <el-col :span="15">
            {{ $t('value_map.levelRange') }}
          </el-col>
        </el-row> -->
        <el-row class="lg__row">
          <el-col :span="2">
            A
          </el-col>
          <el-col :span="2">
            <div
              style="background:green;"
              class="light"
            />
          </el-col>
          <el-col :span="8">
            {{ $t('value_map.rank') }} &lt;= 25%
          </el-col>
          <el-col :span="2">
            B
          </el-col>
          <el-col :span="2">
            <div
              style="background:orange;"
              class="light"
            />
          </el-col>
          <el-col :span="8">
            25% &lt; {{ $t('value_map.rank') }} &lt;= 50%
          </el-col>
        </el-row>
        <el-row class="lg__row">
          <el-col :span="2">
            C
          </el-col>
          <el-col :span="2">
            <div
              style="background:pink;"
              class="light"
            />
          </el-col>
          <el-col :span="8">
            50% &lt; {{ $t('value_map.rank') }} &lt;= 75%
          </el-col>
          <el-col :span="2">
            D
          </el-col>
          <el-col :span="2">
            <div
              style="background:red;"
              class="light"
            />
          </el-col>
          <el-col :span="8">
            75% &lt; {{ $t('value_map.rank') }} &lt;= 100%
          </el-col>
        </el-row>
      </div>
    </el-col>
    <el-col
      :span="6"
      style="text-align:right;"
    >
      <el-button
        style="margin-top:6px;"
        @click="$parent.restore"
      >
        {{ $t('value_map.reload') }}
      </el-button>
      <el-button
        style="margin-top:6px;"
        @click="$parent.export"
      >
        {{ $t('operation.export') }}
      </el-button>
    </el-col>
  </el-row>
</template>

<script>
export default {}
</script>

<style lang="less" scoped>
.wrapper {
  width: 100%;
 font-size: 11px;
  .legend {
    width: 400px;
    border: 1px solid #ccc;
    margin: 2px;
    .lg__row {
      margin: 4px;
      .light {
        height: 10px;
        width: 10px;
        border-radius: 10px;
      }
    }
  }
}
</style>
